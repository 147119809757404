import { useToast } from '@chakra-ui/react';

// Workaround to customise style for Chakra UI v1.6.5
// There's no option to add custom styled to Chakra UI v1.6.5
const addToastStyles = () => {
  if (!document.getElementById('failed-toast-style')) {
    const style = document.createElement('style')
    style.id = 'failed-toast-style'
    style.innerHTML = `
      .chakra-alert {
        white-space: pre-line !important
      }
    `
    document.head.appendChild(style)
  }
}

export const useToasts = (defaultDuration?: number) => {
  const toast = useToast();

  if (typeof window !== 'undefined') {
    addToastStyles();
  }

  const notiSuccess = (title?: string, duration?: number) => {
    toast({
      title: title ?? 'Success',
      status: 'success',
      position: 'top-right',
      duration: duration ?? defaultDuration ?? 5000,
    });
  };

  const notiFailed = (title?: string, duration?: number) => {
    toast({
      title: title ?? 'Failed',
      status: 'error',
      position: 'top-right',
      duration: duration ?? defaultDuration ?? 5000,
    });
  };

  return { notiSuccess, notiFailed };
};
